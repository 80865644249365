import React, { useContext, useEffect, useState } from "react";

import { Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import UploadNurseAnnouncement from "~/components/Appointments/UploadNurseAnnouncement";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import CountryFilter from "~/components/Common/CountryFilter";
import Flex from "~/components/Containers/Flex";
import LoadingError from "~/components/Loaders/LoadingError";
import AnnouncementCard from "~/components/Nurses/AnnouncementCard";
import { FiltersContext } from "~/components/Stores/FilterStore";
import nurseService from "~/utils/api/v1/nurseService";
import { COUNTRY_TO_CODE } from "~/utils/data/constants";
import { Announcement } from "~/utils/interfaces/Announcement";

function HealthTeamAnnouncements() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // @ts-expect-error
  const [filters] = useContext(FiltersContext);

  const fetchAnnouncements = async (currentPage: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await nurseService.fetchNurseAnnouncements(currentPage, COUNTRY_TO_CODE[filters.country]);
      setAnnouncements(response.data.data);
      setCurrentPage(currentPage);
      setTotalPages(response.data.num_pages);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAnnouncements(currentPage);
  }, [currentPage, filters]);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <>
          <h2>Anuncios</h2>
          <Flex
            align="center"
            justify="space-between"
            margin="0 0 2rem 0"
          >
            <Flex
              align="center"
              justify="center"
            >
              <CountryFilter />
            </Flex>
            <UploadNurseAnnouncement />
          </Flex>
          {announcements?.length > 0 && (
            <Flex
              direction="column"
              gap="10px"
            >
              {announcements.map((announcement, index) => (
                <AnnouncementCard
                  key={index}
                  id={announcement.id}
                  title={announcement.title}
                  message={announcement.message}
                  created_at={announcement.created_at}
                  user_fullname={announcement.user_fullname}
                  can_delete={announcement.can_delete}
                  nurses={announcement.nurses}
                  nurses_fullnames={announcement.nurses_fullnames}
                />
              ))}
              <Flex
                direction="column"
                align="center"
                justify="center"
              >
                <Pagination
                  color="primary"
                  shape="rounded"
                  count={totalPages}
                  page={currentPage}
                  onChange={(e: any, value: number) => {
                    fetchAnnouncements(value);
                  }}
                />
              </Flex>
            </Flex>
          )}
          {announcements?.length === 0 && (
            <Flex
              direction="column"
              align="center"
              justify="center"
            >
              <Typography>No hay anuncios para mostrar</Typography>
            </Flex>
          )}
        </>
      )}
    </PrivateRoute>
  );
}

export default HealthTeamAnnouncements;
