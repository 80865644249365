import api from "~/utils/api/api";

const baseUrl = "notification/announcements";

class Announcements {
  listOfReaders = async (id_announcement: string) => {
    return api.get(`${baseUrl}/who-read-it/`, {
      params: {
        is_expired: false,
        model: "nurses.nurses",
        id: id_announcement,
      },
    });
  };
}

const announcements = new Announcements();
export default announcements;
